import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom';
import { useState } from 'react';
import { SideBar } from './component/SideBar';
import { LoginScreen, OTPScreen } from './screen/prelogin/LoginScreen';
import { AuthContextProvider } from "./store/auth-context";
import { HeroScreen } from './screen/prelogin/HeroScreen';
import { useNavigate } from 'react-router-dom';
import { ExampleScreen } from './screen/old/ExampleScreen';
import { SignUpScreen, EmailLoginScreen, VerifyAccountScreen, ForgotPasswordScreen, ResetPasswordScreen } from './screen/prelogin/SignUpScreen';

function App() {
  return (
    <>
      <AuthContextProvider>
        <ExNavigation />
      </AuthContextProvider>
    </>
  );
}
function Navigation() {
  return (
    <html className="h-full bg-white">
      <body className="h-full">
         <BrowserRouter>
          <Routes>
            <Route path="/home/*" element={<SideBar />}>
                <Route index element={<Register />} />
                <Route path="screen1" element={<Screen1 />} />
                <Route path="screen2" element={<Screen2 />} />
            </Route>
            <Route path="ex" element={<ExampleScreen />} />
            <Route path="/" element={<HeroScreen />} />
            <Route path="/login" element={<EmailLoginScreen />} />
            <Route path="/signup" element={<SignUpScreen />} />
            <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
            <Route path="/resetPassword" element={<ResetPasswordScreen />} />
            <Route path="/verifyAccount" element={<VerifyAccountScreen />} />
            <Route path="/validate" element={<OTPScreen />} />
          </Routes>
        </BrowserRouter>
      </body>
    </html>      
  );
}

function ExNavigation() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/home/*" element={<Layout />}>
          <Route index element={<Screen3 />} />
          <Route path="screen4" element={<Screen4 />} />
          <Route path="screen1" element={<Screen1 />} />
          <Route path="screen2" element={<Screen2 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
function Layout() {
  return (
    <div>
      <SideNavBar></SideNavBar>
      <div className="lg:pl-72">
        <TopNavBar></TopNavBar>
        <Outlet />
      </div>  
    </div>
  );
}
function SideNavBar(){
  return (
    <nav className="navbar p-4 pt-20">
      <ul>
        <li>
          <Link to="#">Menu 1</Link>
        </li>
        <li>
          <Link to="#">Menu 2</Link>
        </li>
      </ul>
    </nav>
  );  
}
function TopNavBar(){
  return (
    <nav className="flex space-x-4">
      <a href="/home/screen1">Screen1</a>
      <a href="/home/screen2">Screen2</a>
    </nav>
  );  
}
function Home() {
  //const [activeScreen, setActiveScreen] = useState('screen1');
  const navigate = useNavigate();

  const handleScreenChange = (screen) => {
    //setActiveScreen(screen);
    navigate(`/${screen}`);
  };

  return (
    <div>
      {/* Buttons or other elements to trigger screen changes */}
      <button onClick={() => handleScreenChange('screen1')}>Screen 1</button>
      <button onClick={() => handleScreenChange('screen2')}>Screen 2</button>
      <span>Home Screen</span>
    </div>
  );
}
function Screen1() {
  return (
    <div>
      <h1>Screen1</h1>
    </div>
  );
}
function Screen2() {
  return (
    <div>
      <h1>Screen2</h1>
    </div>
  );
}
function Login() {
  return (
    <div>
      <h1>Login</h1>
    </div>
  );
}
function Register() {
  return (
    <div>
      <h1>Register</h1>
    </div>
  );
}
function Screen3() {
  return (
    <div>
      <h1>Screen3</h1>
    </div>
  );
} 
function Screen4() {
  return (
    <div>
      <h1>Screen4</h1>
    </div>
  );
} 


export default App;
