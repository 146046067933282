import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { APP_CONFIG } from '../config';

const s3client = new S3Client({
    region: APP_CONFIG.AWS_REGION, // Replace with your S3 region
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    },
});

async function uploadFileS3(fobj) {
    console.log("In FileUpload uploadFile  -  " + JSON.stringify(fobj))
    
      const fname = fobj.name
      const params = {
        Bucket: APP_CONFIG.AWS_BUCKET_NAME, // Replace with your bucket name
        Key: APP_CONFIG.AWS_UPLOAD_FOLDER + fname,
        Body: fobj,
      };
      const url = 'https://' + APP_CONFIG.AWS_BUCKET_NAME + '.s3.amazonaws.com/' + APP_CONFIG.AWS_UPLOAD_FOLDER + fname 
      console.log("In FileUpload uploadFile  -  " + JSON.stringify([url, fname])) 
      const r = await s3client.send(new PutObjectCommand(params));
      console.log("In FileUpload uploadFile  response -  " + JSON.stringify(r)) 
      if(r['$metadata'] && r['$metadata']['httpStatusCode'] === 200)
          return {'status':'success', 'fname': fname, 'url': url, 'size': fobj.size, 'hash': await calculateSHA256(fobj)}
      return {'status': 'error'}
}
async function deleteFileS3(fname) {
    try {
      const deleteParams = {
        Bucket: APP_CONFIG.AWS_BUCKET_NAME,
        Key: APP_CONFIG.AWS_UPLOAD_FOLDER + fname,
      };
      const command = new DeleteObjectCommand(deleteParams);
      const response = await s3client.send(command);
      console.log("File deleted successfully", response);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  }
async function calculateSHA256(file) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string
    console.log("In FileUpload calculateSHA256  -  " + hashHex)
    return hashHex;
  }
  
export {uploadFileS3, deleteFileS3}