const APP_CONFIG = {
    get AWS_BUCKET_NAME(){
      return 'f4t'
    },
    get AWS_REGION(){
      return 'us-east-1'
    },
    get AWS_UPLOAD_FOLDER(){
      return 'uploads/'
    }

    
}
export { APP_CONFIG }