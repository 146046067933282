import { createContext, useState, useEffect } from 'react';

const AuthContext = createContext({
  token: {},
  loading: true,
  isAuthenticated: false,
  authenticate: (token) => {},
  logout: () => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    //console.log("AuthContextProvider - useEffect - " + storedToken)
    if (storedToken)
      setAuthToken(JSON.parse(storedToken));
    setLoading(false);
  }, []);


  function authenticate(token) {
    setAuthToken(token);
    localStorage.setItem("token", JSON.stringify(token));
  }

  function logout() {
    console.log("Logging out")
    setAuthToken({});
    localStorage.removeItem("token");
  }

  const value = {
    token: authToken,
    loading: loading,
    isAuthenticated: !!authToken,
    authenticate: authenticate,
    logout: logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export  { AuthContext, AuthContextProvider };